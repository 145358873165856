import { URLRegx } from "./constants";
import moment from "moment";
import * as Yup from "yup";

function formatDate(date: string | Date) {
  return new Date(date).toLocaleDateString();
}

export const loginSchema = Yup.object().shape({
  email: Yup.string()
    .trim()
    .email("Please enter a valid email")
    .min(5, "Must be longer than 5 characters")
    .max(255, "Must be shorter than 255 characters")
    .required("Email is required"),
  password: Yup.string().required("Password is required")
});

export const addBankDetailsSchema = Yup.object().shape({
  account_name: Yup.string().trim().required("Account Name is required"),
  account_number: Yup.string().required("Account Number is required")
});

export const addNewBusinessSchema = Yup.object().shape({
  description: Yup.string()
    .trim()
    .min(5, "Must be longer than 5 characters")
    .max(255, "Must be shorter than 255 characters")
    .required("Description is required"),
  website: Yup.string().matches(URLRegx, "URL is not valid"),
  legal_business_name: Yup.string()
    .required("Legal business name is required")
    .min(3, "Must be longer than 3 characters")
    .max(15, "Must be shorter than 15 characters"),
  trading_name: Yup.string()
    .trim()
    .required("Trading name is required")
    .min(3, "Must be longer than 3 characters")
});

export const rcNumberSchema = Yup.object().shape({
  rc_number: Yup.number()
    .typeError("Please enter a valid RC Number")
    .required("RC Number is required")
    .test("validity of length", "Please enter a valid RC Number", (val) => {
      if (val !== undefined) {
        return val !== undefined && val.toString().length > 5;
      }
      return false;
    })
});

export const TaxNumberSchema = Yup.object().shape({
  tax_identification_number: Yup.number()
    .typeError("Please enter a valid TIN")
    .required("TIN is required")
    .test("validity of length", "Please enter a valid TIN", (val) => {
      if (val !== undefined) {
        return val.toString().length > 5;
      }
      return false;
    })
});

export const invitationCodeSchema = Yup.object().shape({
  code: Yup.string()
    .trim()
    .required("Invitaion code is required")
    .equals(["TRY#DUPLO"], "Invalid invitation code")
});

export const walletTransferSchema = Yup.object().shape({
  policies_count: Yup.number(),
  amount: Yup.number().required("Amount is required").moreThan(0),
  description: Yup.string()
    .trim()
    .min(5, "Must be longer than 5 characters")
    .max(100, "Must be shorter than 100 characters")
    .required("Description is required")
});

export const policyCreationSchema = Yup.object().shape({
  policy_name: Yup.string()
    .trim()
    .min(5, "Must be longer than 5 characters")
    .max(50, "Must be shorter than 50 characters")
    .required("Policy name is required"),
  infinite_max_amount: Yup.boolean(),
  infinte_marker: Yup.string().default("Infinite"),
  any_approver_can_complete: Yup.boolean(),
  policy_criteria_type: Yup.string().test(
    "text value",
    "Select a valid policy type",
    (val) => {
      if (val !== undefined) {
        return (
          val.toString() === "Bill Amount" ||
          val.toString() === "Bill Vendor" ||
          val.toString() === "Payout Amount"
        );
      }
      return false;
    }
  ),
  policy_use_case: Yup.string().test(
    "text value",
    "Select a valid policy use case",
    (val) => {
      if (val !== undefined) {
        return (
          val.toString() === "All Amount" || val.toString() === "Custom Amount"
        );
      }
      return false;
    }
  ),
  policy_criteria_min_value: Yup.number()

    .typeError("Please enter a valid min amount")
    .when("policy_criteria_type", {
      is: (value: string) =>
        value === "Bill Amount" || value === "Payout Amount",
      then: (schema) =>
        schema
          .required("Minimum amount is required")
          .moreThan(99.9, "Minimum amount must be greater or equal to ₦100"),
      otherwise: (schema) => schema.min(0).max(0)
    }),
  policy_criteria_max_value: Yup.number()
    .typeError("Please enter a valid max amount")
    .when("policy_criteria_type", {
      is: (value: string) =>
        value === "Bill Amount" || value === "Payout Amount",
      then: (schema) =>
        schema
          .required("Maximum amount is required")
          .moreThan(
            Yup.ref("policy_criteria_min_value"),
            "Max amount must be greater than min amount"
          ),
      otherwise: (schema) => schema.min(0).max(0)
    }),
  policy_criteria_value: Yup.string()
    .typeError("Please enter a valid vendor name")
    .when("policy_criteria_type", {
      is: (value: string) =>
        value === "Bill Amount" || value === "Payout Amount",
      then: (schema) => schema.min(0).max(0),
      otherwise: (schema) =>
        schema.min(5).max(60).required("Please enter a valid vendor name")
    })
});

export const payoutPreviewSchema = Yup.object().shape({
  amount: Yup.number().required("Amount is required").moreThan(0),
  description: Yup.string()
    .trim()
    .min(5, "Must be longer than 5 characters")
    .max(100, "Must be shorter than 100 characters"),
  reference: Yup.string()
    .trim()
    .min(5, "Must be longer than 5 characters")
    .max(100, "Must be shorter than 100 characters")
});

export const signupSchema = Yup.object().shape({
  first_name: Yup.string()
    .trim()
    .required("First name is required")
    .matches(
      /^(?:([A-Za-z\u00C0-\u00D6\u00D8-\u00f6\u00f8-\u00ff\s]*))$/g,
      "Enter a valid name"
    ),
  last_name: Yup.string()
    .trim()
    .required("Last name is required")
    .matches(
      /^(?:([A-Za-z\u00C0-\u00D6\u00D8-\u00f6\u00f8-\u00ff\s]*))$/g,
      "Enter a valid name"
    ),
  phone_number: Yup.string().required("Phone number is required"),
  email: Yup.string()
    .trim()
    .email("Please enter a valid email")
    .min(5, "Must be longer than 5 characters")
    .max(255, "Must be shorter than 255 characters")
    .required("Email is required"),
  password: Yup.string()
    .required("Password is required")
    .min(8, "Password too short")
    .matches(/^(?=.*[a-z])/, "Must contain at least one lowercase character")
    .matches(/^(?=.*[A-Z])/, "Must contain at least one uppercase character")
    .matches(/^(?=.*[0-9])/, "Must contain at least one number"),
  trading_name: Yup.string()
    .trim()
    .required("Business name is required")
    .min(3, "Must be longer than 3 characters"),
  invite_code: Yup.string().trim().optional(),

  other_account_process_management: Yup.string().when(
    "account_process_management",
    {
      is: "others",
      then: Yup.string()
        .required("This field is required")
        .test(
          "validity of text",
          "Only alphabets and numbers are allowed",
          (val) => {
            if (val !== undefined) {
              return /^[a-zA-Z0-9\s ]*$/.test(String(val));
            }
            return false;
          }
        )
        .min(5, "Minimum characters should be 5")
        .max(50, "Maximum characters should be 50"),
      otherwise: Yup.string().notRequired()
    }
  ),

  is_business_registered: Yup.string()
    .nullable()
    .required("Please select if your business is registered"),
  account_process_management: Yup.string().required(
    "Please select your accounting management process"
  )
});

export const changePasswordSchema = Yup.object().shape({
  password: Yup.string().required("Password is required"),
  new_password: Yup.string()
    .required("New Password is required")
    .min(8, "Password too short")
    .matches(/^(?=.*[a-z])/, "Must contain at least one lowercase character")
    .matches(/^(?=.*[A-Z])/, "Must contain at least one uppercase character")
    .matches(/^(?=.*[0-9])/, "Must contain at least one number"),
  confirm_password: Yup.string()
    .required("Confirm Password is required")
    .oneOf([Yup.ref("new_password"), null], "Passwords must match")
});

export const addBillsSchema = Yup.object().shape({
  invoice_number: Yup.string()
    .trim()
    .min(3, "Must not be less than 3 digits")
    .max(100, "Must not be more than 100 digits")
    .required("Invoice value is required")
    .test("validity of digits", "Please enter a valid Invoice value", (val) => {
      if (val !== undefined) {
        return /^([\w-]{3,100})$/.test(String(val));
      }
      return false;
    }),
  invoice_date: Yup.date()
    .typeError("Enter a invoice date")
    .required("Invoice date is required"),
  due_date: Yup.date()
    .typeError("Enter a due date")
    .min(
      Yup.ref("invoice_date"),
      ({ min }) => `Cannot be less than ${formatDate(min)}`
    )
    .required("Due date is required"),
  payment_terms: Yup.string().trim(),
  amount: Yup.number()
    .moreThan(99.9, "Amount must be greater or equal to ₦100")
    .required("Amount is required"),
  description: Yup.string()
    .trim()
    .min(5, "Must be longer than 5 characters")
    .max(500, "Must not be more than 500 characters")
    .required("Description is required"),
  purchase_order_number: Yup.string().trim(),
  vendor_ref: Yup.string().trim().required("Vendor is required")
});

export const addBillPaymentSchema = Yup.object().shape({
  amount: Yup.number()
    .moreThan(99.9, "Minimium amount must be greater than or equal to ₦100")
    .required("Amount is required")
});

export const addVendorsRecipientsSchema = Yup.object().shape({
  email: Yup.string()
    .trim()
    .email("Please enter a valid email")
    .min(5, "Must be longer than 5 characters")
    .max(255, "Must be shorter than 255 characters")
    .required("Email is required"),
  company_name: Yup.string().required("Company Name is required"),
  primary_contact: Yup.string().required("Primary Contact is required"),
  phone_number: Yup.string().required("Phone Number is required"),

  // Address
  shipping_addresses: Yup.string().required("Shipping addess is required"),
  billing_addresses: Yup.string().optional(),

  // City
  shipping_city: Yup.string().required("Shipping city is required"),
  billing_city: Yup.string().optional()
});
export const addOthersRecipientsSchema = Yup.object().shape({
  email: Yup.string()
    .trim()
    .email("Please enter a valid email")
    .min(5, "Must be longer than 5 characters")
    .max(255, "Must be shorter than 255 characters"),

  account_number: Yup.string().required("Account Number is required")
});

export const AddDirectorSchema = Yup.object().shape({
  firstname: Yup.string()
    .trim()
    .required("First name is required")
    .matches(
      /^(?:([A-Za-z\u00C0-\u00D6\u00D8-\u00f6\u00f8-\u00ff\s]*))$/g,
      "Enter a valid first name"
    ),
  lastname: Yup.string()
    .trim()
    .required("Last name is required")
    .matches(
      /^(?:([A-Za-z\u00C0-\u00D6\u00D8-\u00f6\u00f8-\u00ff\s]*))$/g,
      "Enter a valid last name"
    ),
  phone_number: Yup.string().required("Phone number is required"),
  date_of_birth: Yup.string()
    .trim()
    .required("Date of Birth is required")
    .test("date_of_birth", "Must not be less than 18 years old", (value) => {
      return moment().diff(moment(value), "years") >= 18;
    }),

  city: Yup.string()
    .trim()
    .required("City is required")
    .matches(/^[A-Za-z]*$/, "Please enter a valid city"),
  town: Yup.string().trim(),
  address: Yup.string().trim().required("Address is required"),
  identification_number: Yup.string().required(
    "Identification number is required"
  )
});

export const profileSchema = Yup.object().shape({
  phone_number: Yup.string().required("Phone number is required")
});
export const businessProfileSchema = Yup.object().shape({
  email: Yup.string()
    .email("Please enter a valid email")
    .required("Email is required")
});

export const directorFormSchema = Yup.object().shape({
  first_name: Yup.string()
    .trim()
    .required("First name is required")
    .matches(
      /^(?:([A-Za-z\u00C0-\u00D6\u00D8-\u00f6\u00f8-\u00ff\s]*))$/g,
      "Enter a valid first name"
    ),
  last_name: Yup.string()
    .trim()
    .required("Last name is required")
    .matches(
      /^(?:([A-Za-z\u00C0-\u00D6\u00D8-\u00f6\u00f8-\u00ff\s]*))$/g,
      "Enter a valid last name"
    ),
  address: Yup.string().required("Address is required"),
  bvn: Yup.number()
    .typeError("Please enter a valid bvn")
    .required("BVN is required")
    .test("validity of length", "Please enter a valid bvn", (val) => {
      if (val !== undefined) {
        return val.toString().length === 11;
      }
      return false;
    })
});

export const forgotPasswordSchema = Yup.object().shape({
  email: Yup.string()
    .trim()
    .email("Please enter a valid email")
    .min(5, "Must be longer than 5 characters")
    .max(255, "Must be shorter than 255 characters")
    .required("Email is required")
});

export const resetPasswordSchema = Yup.object().shape({
  newPassword: Yup.string()
    .required("Password is required")
    .min(8, "Password too short")
    .matches(/^(?=.*[a-z])/, "Must contain at least one lowercase character")
    .matches(/^(?=.*[A-Z])/, "Must contain at least one uppercase character")
    .matches(/^(?=.*[0-9])/, "Must contain at least one number"),
  confirmPassword: Yup.string()
    .required("Confirm Password is required")
    .oneOf([Yup.ref("newPassword"), null], "Passwords must match")
});

export const updateOnWallet = Yup.object().shape({
  bvn: Yup.number()
    .typeError("Please enter a valid bvn")
    .required("BVN is required")
    .test("validity of length", "Please enter a valid bvn", (val) => {
      if (val !== undefined) {
        return val.toString().length === 11;
      }
      return false;
    })
});

export const updateOnBasicWallet = Yup.object().shape({
  bvn: Yup.number()
    .typeError("Please enter a valid bvn")
    .test("validity of length", "Please enter a valid bvn", (val) => {
      if (val !== undefined) {
        return val.toString().length === 11;
      }
      return true;
    })
});

export const altNewWalletSchema = Yup.object().shape({
  name: Yup.string()
    .trim()
    .required("Name is required")
    .matches(
      /^(?:([-A-Za-z\u00C0-\u00D6\u00D8-\u00f6\u00f8-\u00ff\s0-9]*)) (?:([-A-Za-z\u00C0-\u00D6\u00D8-\u00f6\u00f8-\u00ff\s0-9]*))$/g,
      "Enter a valid full name"
    ),
  email: Yup.string()
    .trim()
    .email("Please enter a valid email")
    .min(5, "Must be longer than 5 characters")
    .max(255, "Must be shorter than 255 characters")
    .required("Email is required"),
  bvn: Yup.number()
    .typeError("Please enter a valid bvn")
    .when("canAddWallet", {
      is: (value: boolean | undefined) =>
        value === false || value === undefined,
      then: (schema) => schema.min(0),
      otherwise: (schema) => schema.min(11).required("Please enter a valid bvn")
    })
    .test("validity of length", "Please enter a valid bvn", (val) => {
      if (val !== undefined) {
        return val.toString().length === 11;
      }
      return false;
    }),
  phone_number: Yup.string().required("Phone number is required")
});

export const newWalletSchema = Yup.object().shape({
  name: Yup.string()
    .trim()
    .required("Name is required")
    .matches(
      /^(?:([-A-Za-z\u00C0-\u00D6\u00D8-\u00f6\u00f8-\u00ff\s0-9]*)) (?:([-A-Za-z\u00C0-\u00D6\u00D8-\u00f6\u00f8-\u00ff\s0-9]*))$/g,
      "Enter a valid full name"
    ),
  email: Yup.string()
    .trim()
    .email("Please enter a valid email")
    .min(5, "Must be longer than 5 characters")
    .max(255, "Must be shorter than 255 characters")
    .required("Email is required"),
  bvn: Yup.number()
    .typeError("Please enter a valid bvn")
    .min(0, "")
    .test("validity of length", "Please enter a valid bvn", (val) => {
      if (val !== undefined) {
        return val.toString().length === 11;
      }
      return true;
    }),
  phone_number: Yup.string().required("Phone number is required")
});

export const addNewInvoiceSchema = Yup.object().shape({
  title: Yup.string().trim().max(500, "Must be shorter than 500 characters"),
  invoicenumber: Yup.number().required("Invoice number required")
});

export const markInvoiceAsPaidSchema = Yup.object().shape({
  reason: Yup.string()
    .trim()
    .min(5, "Must be longer than 5 characters")
    .max(100, "Must be shorter than 100 characters")
    .required("Description is required")
});

export const addCustomerSchema = Yup.object().shape({
  name: Yup.string()
    .trim()
    .required("Name is required")
    .matches(
      /^(?:([A-Za-z0-9\-\u00C0-\u00D6\u00D8-\u00f6\u00f8-\u00ff\s]*)) (?:([A-Za-z0-9\-\u00C0-\u00D6\u00D8-\u00f6\u00f8-\u00ff\s]*))$/g,
      "Enter a valid full name"
    ),
  phone_number: Yup.string().required("Phone number is required"),
  email: Yup.string()
    .trim()
    .email("Please enter a valid email")
    .min(5, "Must be longer than 5 characters")
    .max(255, "Must be shorter than 255 characters")
    .required("Email is required")
});

export const addTeamMemberModalSchema = Yup.object().shape({
  full_name: Yup.string()
    .trim()
    .required("Full name is required")
    .matches(
      /^(?:([A-Za-z\u00C0-\u00D6\u00D8-\u00f6\u00f8-\u00ff\s]*)) (?:([A-Za-z\u00C0-\u00D6\u00D8-\u00f6\u00f8-\u00ff\s]*))$/g,
      "Enter a valid full name"
    ),
  email: Yup.string()
    .trim()
    .email("Please enter a valid email")
    .min(5, "Must be longer than 5 characters")
    .max(255, "Must be shorter than 255 characters")
    .required("Email is required"),
  phone_number: Yup.string().required("Phone number is required")
});

export const addTeamMemberSchema = Yup.object().shape({
  email: Yup.string()
    .trim()
    .email("Please enter a valid email")
    .min(5, "Must be longer than 5 characters")
    .max(255, "Must be shorter than 255 characters")
    .required("Email is required")
});

export const memberActivationSchema = Yup.object().shape({
  full_name: Yup.string()
    .trim()
    .required("Full name is required")
    .matches(
      /^(?:([A-Za-z\u00C0-\u00D6\u00D8-\u00f6\u00f8-\u00ff\s]*)) (?:([A-Za-z\u00C0-\u00D6\u00D8-\u00f6\u00f8-\u00ff\s]*))$/g,
      "Enter a valid full name"
    ),
  email: Yup.string()
    .trim()
    .email("Please enter a valid email")
    .min(5, "Must be longer than 5 characters")
    .max(255, "Must be shorter than 255 characters")
    .required("Email is required"),
  phone_number: Yup.string().required("Phone number is required"),
  password: Yup.string()
    .required("Password is required")
    .min(8, "Password too short")
    .matches(/^(?=.*[a-z])/, "Must contain at least one lowercase character")
    .matches(/^(?=.*[A-Z])/, "Must contain at least one uppercase character")
    .matches(/^(?=.*[0-9])/, "Must contain at least one number"),
  confirm_password: Yup.string()
    .required("Confirm Password is required")
    .oneOf([Yup.ref("password"), null], "Passwords must match")
});

export const bulkPayoutEditSchema = Yup.object().shape({
  amount: Yup.number().required("Amount is required").min(100),
  account_name: Yup.string().trim().optional(),
  account_number: Yup.string().required("Account Number is required"),
  email: Yup.string()
    .trim()
    .email("Please enter a valid email")
    .min(5, "Must be longer than 5 characters")
    .max(255, "Must be shorter than 255 characters")
    .notRequired(),
  description: Yup.string()
    .trim()
    .min(5, "Must be longer than 5 characters")
    .max(100, "Must be shorter than 100 characters")
    .notRequired(),
  date: Yup.string().trim().required("Date is required")
});
export const bulkPayoutAddRecipientSchema = Yup.object().shape({
  amount: Yup.number().required("Amount is required").moreThan(0),
  account_number: Yup.string().required("Account Number is required"),
  email: Yup.string()
    .trim()
    .email("Please enter a valid email")
    .min(5, "Must be longer than 5 characters")
    .max(255, "Must be shorter than 255 characters")
    .notRequired(),
  date: Yup.string().trim().required("Date is required")
});

export const otpPreferenceSchema = Yup.object().shape({
  email: Yup.object().shape({
    email: Yup.string()
      .email("Please enter a valid email")
      .min(5, "Must be longer than 5 characters")
      .max(255, "Must be shorter than 255 characters")
  }),
  sms: Yup.object().shape({
    phone_number: Yup.string().required("Phone number is required")
  })
});

export const denyApprovalSchema = Yup.object().shape({
  deny_reason: Yup.string().required("Reason is required")
});

export const customRoleSchema = Yup.object().shape({
  role_name: Yup.string()
    .trim()
    .required("Role Name is required")
    .test(
      "validity of text",
      "Only alphabets and numbers are allowed",
      (val) => {
        if (val !== undefined) {
          return /^[a-zA-Z0-9\s]*$/.test(String(val));
        }
        return false;
      }
    ),
  description: Yup.string()
    .required("Description is required")
    .max(50, "Must be shorter than 50 characters")
    .test(
      "validity of description",
      "Only alphabets and numbers are allowed",
      (val) => {
        if (val !== undefined) {
          return /^[a-zA-Z0-9\s]*$/.test(String(val));
        }
        return false;
      }
    )
});

export const expenseCreationSchema = Yup.object().shape({
  alias: Yup.string()
    .trim()
    .required("Alias is required")
    .test(
      "validity of text",
      "Only alphabets, numbers, commas & spaces allowed",
      (val) => {
        if (val !== undefined) {
          return /^[\w,\s]+$/.test(String(val));
        }
        return false;
      }
    ),
  monthly_budget: Yup.string().optional()
});

export const moveFundsSchema = Yup.object().shape({
  amount: Yup.number()
    .moreThan(0, "Amount must be greter than 0")
    .required("Amount is required")
});

export const denyFinancingSchema = Yup.object().shape({
  reason: Yup.string().required("Please add a reason")
});

export const addDepartmentSchema = Yup.object().shape({
  department_name: Yup.string()
    .trim()
    .required("Department name is required")
    .min(3, "Department name is too short")
    .max(50, "Must be shorter than 50 characters")
    .matches(/^[A-Za-z\s]*$/, "Department name should contain letters only")
});

export const intlPaymentSchema = Yup.object().shape({
  swift_code: Yup.string()
    .trim()
    .min(3, "Must not be less than 3 characters")
    .max(18, "Must not be more than 18 characters")
    .required("Swift code is required")
    .matches(/^[A-Za-z0-9]*$/, "Please enter only alphanumeric characters"),
  beneficiary_bank: Yup.string()
    .trim()
    .min(3, "Must not be less than 3 characters")
    .required("Beneficiary bank is required to proceed")
    .matches(/^[A-Za-z ]*$/, "Please enter a valid Beneficiary Bank"),
  account_number: Yup.string()
    .min(3, "Must not be less than 3 characters")
    .required("Account Number is required")
    .matches(/^[0-9]*$/, "Please enter a valid account number"),

  account_name: Yup.string()
    .trim()
    .required("Beneficiary name is required")
    .matches(/^[A-Za-z ]*$/, "Please enter a valid Beneficiary Name"),
  amount: Yup.number()
    .moreThan(99.9, "Minimum amount must be greater or equal to 100")
    .required("Amount is required"),
  amount_in_ngn: Yup.number()
    .moreThan(99.9, "Minimum amount must be greater or equal to ₦100")
    .required("Amount in Naira is required"),
  payment_purpose: Yup.string()
    .trim()
    .min(5, "purpose must be longer 5 characters")
    .required("Purpose of payment is required")
    .matches(/^[A-Za-z0-9 ,.:-]*$/, "Please enter a valid purpose of payment"),
  narration: Yup.string()
    .trim()
    .optional()
    .matches(/^[A-Za-z0-9 ,.:-]*$/, "Please enter valid narration"),
  other_details: Yup.string()
    .optional()
    .matches(/^[A-Za-z0-9 ,.:-]*$/, "Please enter valid other details"),
  email: Yup.string()
    .trim()
    .email("Please enter a valid email")
    .min(5, "Must be longer than 5 characters")
    .max(255, "Must be shorter than 255 characters")
    .required("Email is required"),
  phone_number: Yup.string()
    .min(4, "Phone number is required")
    .required("Phone number is required"),
  address: Yup.string()
    .min(5, "Must be longer than 5 characters")
    .required("Address is required")
    .matches(/^[A-Za-z0-9 ,.:-]*$/, "Please enter a valid address"),
  city: Yup.string()
    .required("city is required")
    .matches(/^[A-Za-z -]*$/, "Please enter a valid city"),
  post_code: Yup.string()
    .required("Post code is required")
    .matches(/^[A-Za-z0-9]*$/, "Please enter a valid postal code")
});

export const newExpenseSchema = Yup.object().shape({
  amount: Yup.number()
    .moreThan(99.9, "Minimium amount must be greater than or equal to ₦100")
    .required("Amount is required"),
  date: Yup.string().trim().required("Date is required"),
  purpose: Yup.string()
    .required("Purpose is required")
    .min(5, "Must be longer than 5 characters")
    .max(255, "Must be shorter than 255 characters")
});

export const businessTransferSchema = Yup.object().shape({
  amount: Yup.number().required("Amount is required").moreThan(0),
  description: Yup.string()
    .trim()
    .matches(
      /^[a-zA-Z0-9 +_'“&-]{3,50}$/,
      "Description must be alphanumeric, and be between 3 and 50 characters long. Characters such as '@#%!*( )_+= {}' is not accepted"
    )
    .required("Description is required")
});
